import type { LoaderArgs } from "@remix-run/cloudflare";
import { Link, useLoaderData } from "@remix-run/react";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Hangman } from "~/components/Hangman";
import db from "~/db";

const ALPHABET = "abcdefghijklmnopqrstuvwxyz".split("");

type GameState = "playing" | "won" | "lost";

export type Game = {
  word: string;

  state: GameState;
  wrongGuesses: string[];
  goodGuesses: string[];
};

export const loader = async ({ params }: LoaderArgs) => {
  const docRef = doc(db, "games", params.gameId || "");
  const doctSnap = await getDoc(docRef);
  if (doctSnap.exists()) {
    return { ...doctSnap.data(), id: docRef.id } as {
      word: string;
      id: string;
    };
  }
  return { word: "", id: "" };
};

export default function GameRoute() {
  const secret = useLoaderData<typeof loader>();

  const [game, setGame] = useState<Game>({
    word: secret.word,
    state: "playing",
    wrongGuesses: [],
    goodGuesses: [],
  });

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "games", secret.id), (doc) => {
      console.log("Current data: ", doc.data());
      setGame(doc.data() as Game);
    });
    return () => unsub();
  }, [secret.id]);

  const hangleGuess = (letter: string) => {
    let { wrongGuesses, goodGuesses, word } = game;
    if (!game.word.includes(letter)) {
      wrongGuesses = [...wrongGuesses, letter];
    } else {
      goodGuesses = [...goodGuesses, letter];
    }
    let state: GameState = game.state;
    if (goodGuesses.length === new Set(word.split("")).size) {
      state = "won";
    } else if (wrongGuesses.length > 5) {
      state = "lost";
    }
    updateDoc(doc(db, "games", secret.id), {
      ...game,
      state,
      wrongGuesses,
      goodGuesses,
    });
  };
  return (
    <div className="p-4">
      <h1 className="text-7xl mb-4">Le Pendu 😵‍💫</h1>

      <Hangman game={game} />

      <div className="flex justify-center gap-2 text-lg my-4">
        {game.word.split("").map((letter, i) => (
          <div key={i}>
            {game.goodGuesses.includes(letter) ||
            ["lost", "won"].includes(game.state)
              ? letter
              : "_"}
          </div>
        ))}
      </div>
      <div className="mx-auto  flex flex-wrap justify-center max-w-sm">
        {ALPHABET.map((letter) => (
          <button
            key={letter}
            onClick={() => hangleGuess(letter)}
            disabled={
              ["lost", "won"].includes(game.state) ||
              game.goodGuesses.includes(letter) ||
              game.wrongGuesses.includes(letter)
            }
            className={`px-4 py-2 m-2 rounded-lg shadow-lg bg-white
            ${game.goodGuesses.includes(letter) && "bg-green-500"}
            ${game.wrongGuesses.includes(letter) && "bg-red-500"}
            ${
              game.goodGuesses.includes(letter) ||
              game.wrongGuesses.includes(letter)
                ? "cursor-not-allowed"
                : "hover:bg-gray-200 focus:outline-none focus:shadow-outline active:bg-gray-300"
            }

          text-gray-800 font-bold`}
          >
            {letter}
          </button>
        ))}
      </div>
      {game.state === "won" && (
        <h1 className="text-4xl text-center mt-3">Gagné !!! 🎉</h1>
      )}
      {game.state === "lost" && (
        <h1 className="text-4xl text-center mt-3">Perdu... 🙃</h1>
      )}
      <Link
        to="/"
        className="rounded-lg px-4 py-2 bg-amber-400 text-xl mt-8 inline-block"
      >
        Créer une nouvelle partie
      </Link>
    </div>
  );
}
